import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IOption} from "ng-select";
import {CartService} from "../../services/cart.service";

@Component({
  selector: 'app-listing-form',
  templateUrl: './listing-form.component.html',
  styleUrls: ['./listing-form.component.scss']
})
export class ListingFormComponent implements OnInit {
  @Output('closeListingEmit') closeListingEmitter = new EventEmitter();
  cardMonths: IOption[] = [
    {
      value: '1',
      label: 'January'
    },
    {
      value: '2',
      label: 'February'
    },
    {
      value: '3',
      label: 'March'
    },
    {
      value: '4',
      label: 'April'
    },
    {
      value: '5',
      label: 'May'
    },
    {
      value: '6',
      label: 'June'
    },
    {
      value: '7',
      label: 'July'
    },
    {
      value: '8',
      label: 'August'
    },
    {
      value: '9',
      label: 'September'
    },
    {
      value: '10',
      label: 'October'
    },
    {
      value: '11',
      label: 'November'
    },
    {
      value: '12',
      label: 'December'
    },
  ];

  cardYears: IOption[] = [
    {
      value: '2019',
      label: '2019',
    },
    {
      value: '2020',
      label: '2020',
    },
    {
      value: '2021',
      label: '2021',
    },
    {
      value: '2022',
      label: '2022',
    },
    {
      value: '2023',
      label: '2023',
    },
  ];
  constructor(public cartService: CartService) { }

  ngOnInit() {
  }

  closeListing() {
    this.closeListingEmitter.emit(false);
  }
}
