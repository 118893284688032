import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from "./shared/shared.module";
import {HeaderComponent} from "./shared/components/header/header.component";
import {FooterComponent} from "./shared/components/footer/footer.component";
import {EntryComponent} from "./shared/components/entry/entry.component";
import {BuyComponent} from "./buy/buy.component";
import {SellComponent} from "./sell/sell.component";
import {SellAdditionalComponent} from "./sell/sell-additional/sell-additional.component";
import {SellConditionsComponent} from "./sell/sell-conditions/sell-conditions.component";
import {SellDescriptionComponent} from "./sell/sell-description/sell-description.component";
import {SellImagesComponent} from "./sell/sell-images/sell-images.component";
import {SellItemInfoComponent} from "./sell/sell-item-info/sell-item-info.component";
import {WizardComponent} from "./shared/components/wizard/wizard.component";
import {WizardCaptionsComponent} from "./shared/components/wizard/wizard-captions/wizard-captions.component";
import {WizardContentComponent} from "./shared/components/wizard/wizard-content/wizard-content.component";
import {WizardControlsComponent} from "./shared/components/wizard/wizard-controls/wizard-controls.component";
import {CategoryTilesComponent} from "./shared/components/category-tiles/category-tiles.component";
import {CategoryTileComponent} from "./shared/components/category-tiles/category-tile/category-tile.component";
import {FileNameComponent} from "./shared/components/file-name/file-name.component";
import {WizardControlComponent} from "./shared/components/wizard/wizard-controls/wizard-control/wizard-control.component";
import {DetailedOptionComponent} from "./shared/components/detailed-option/detailed-option.component";
import {CartComponent} from "./shared/components/cart/cart.component";
import {ListingFormComponent} from "./shared/components/listing-form/listing-form.component";
import { BuyItemInfoComponent } from './buy/buy-item-info/buy-item-info.component';
import { BuyDescriptionComponent } from './buy/buy-description/buy-description.component';
import { BuyDeliveryComponent } from './buy/buy-delivery/buy-delivery.component';
import { BuyImagesComponent } from './buy/buy-images/buy-images.component';
import { BuyAdditionalComponent } from './buy/buy-additional/buy-additional.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    EntryComponent,
    BuyComponent,
    SellComponent,
    SellAdditionalComponent,
    SellConditionsComponent,
    SellDescriptionComponent,
    SellImagesComponent,
    SellItemInfoComponent,
    WizardComponent,
    WizardCaptionsComponent,
    WizardContentComponent,
    WizardControlsComponent,
    CategoryTilesComponent,
    CategoryTileComponent,
    FileNameComponent,
    WizardControlComponent,
    DetailedOptionComponent,
    CartComponent,
    ListingFormComponent,
    BuyItemInfoComponent,
    BuyDescriptionComponent,
    BuyDeliveryComponent,
    BuyImagesComponent,
    BuyAdditionalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
