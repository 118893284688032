import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {WizardContentComponent} from "../wizard/wizard-content/wizard-content.component";

@Component({
  selector: 'app-classified',
  templateUrl: './classified.component.html',
  styleUrls: ['./classified.component.scss']
})
export class ClassifiedComponent implements OnInit {

  isBlurred = false;
  isOptionDetailsOpened = false;
  isCartOpened = false;
  isListingOpened = false;
  detailPic = null;
  @ViewChildren(WizardContentComponent) blocksQueryList: QueryList<WizardContentComponent>;
  blocks: WizardContentComponent[] = null;

  constructor() {
  }

  ngOnInit(): void {

  }

  toggleBlur() {
    this.isBlurred = !this.isBlurred;
  }

  showDetailsPic(value: string) {
    this.toggleBlur();
    this.detailPic = value;
    this.isOptionDetailsOpened = true;
  }

  closeDetailsPic(value: boolean) {
    this.toggleBlur();
    this.isOptionDetailsOpened = value;
  }

  toggleCart(value: boolean) {
    this.toggleBlur();
    this.isCartOpened = value;
  }

  toggleListing(value: boolean) {
    this.toggleBlur();
    this.isListingOpened = value;
  }

  ngAfterViewInit(): void {
    this.blocks = this.blocksQueryList.toArray();
  }

  getActiveBlockIndex() {
    return this.blocks.indexOf(this.blocks.filter(block => {
      return block.active;
    })[0]);
  }

  setBlockActive(idx: number) {
    this.blocks.forEach(block => {
      block.active = false;
    });

    this.blocks[idx].active = true;
  }

  blocksNavigation(type: string) {
    const activeIndex = this.getActiveBlockIndex();

    if (type === 'prev' && activeIndex > 0) {
      this.setBlockActive(activeIndex - 1);
    } else if (type === 'next' && activeIndex < this.blocks.length - 1) {
      this.setBlockActive(activeIndex + 1);
    }
  }

}
