import {Injectable} from '@angular/core';
import {AdditionalOption} from "../../sell/sell-additional/sell-additional.component";

@Injectable({
  providedIn: 'root'
})
export class CartService {
  products: AdditionalOption[] = [];

  constructor() {
  }

  getProducts() {
    return this.products;
  }

  addProduct(product): void {
    this.products.push(product);
  }

  removeProduct(product): void {
    const productIndex = this.products.indexOf(product);
    this.products.splice(productIndex, 1);
  }

  getTotalPrice(): number {
    return this.products.reduce((acc, product) => {
      return acc += product.price;
    }, 0)
  }
}
