import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Category} from "../sell-item-info/sell-item-info.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-sell-description',
  templateUrl: './sell-description.component.html',
  styleUrls: ['./sell-description.component.scss']
})
export class SellDescriptionComponent implements OnInit {
  form: FormGroup;
  categories: Category[] = [
    new Category('Wheels and Tires', 'wheels and tires', 'Subcategory', 'Subsubcategory', true)
  ];
  @ViewChild('negotiable', {static: false})
  negotiable: ElementRef;
  @Output('navBlocksEmit') navBlocksEmitter = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.form = new FormGroup({
      price: new FormControl(null, [Validators.required]),
      negotiable: new FormControl({value: null, disabled: true}),
      contactName: new FormControl(null, [Validators.required]),
      contactPhone: new FormControl(null),
      condition: new FormControl(null, [Validators.required]),
      usedOptions: new FormControl('excellent'),
      location: new FormControl(null, [Validators.required]),
      itemDescription: new FormControl(null, [Validators.required])
    });
  }

  checkNegotiableState() {
    if (this.form.get('price').value !== 'amount') {
      this.form.patchValue({
        negotiable: null
      });

      this.form.get('negotiable').disable();
    } else {
      this.form.get('negotiable').enable();
    }
  }

  navigateBlocks(type) {
    this.navBlocksEmitter.emit(type);
  }
}
