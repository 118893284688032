import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {EntryComponent} from "./shared/components/entry/entry.component";
import {BuyComponent} from "./buy/buy.component";
import {SellComponent} from "./sell/sell.component";

const routes: Routes = [
  {
    path: '',
    component: EntryComponent
  },
  {
    path: 'buy',
    component: BuyComponent
  },
  {
    path: 'sell',
    component: SellComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
