import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-wizard-content',
  templateUrl: './wizard-content.component.html',
  styleUrls: ['./wizard-content.component.scss']
})
export class WizardContentComponent implements OnInit {
  @Input('label') label: string;
  @HostBinding('class.active') @Input('active') active = false;

  constructor() {
  }

  ngOnInit() {

  }
}
