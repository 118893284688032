import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-buy-images',
  templateUrl: './buy-images.component.html',
  styleUrls: ['./buy-images.component.scss']
})
export class BuyImagesComponent implements OnInit {
  @Output('navBlocksEmit') navBlocksEmitter = new EventEmitter();
  isBottomPartShowed = false;
  constructor() { }

  ngOnInit() {
  }

  navigateBlocks(type) {
    this.navBlocksEmitter.emit(type);
  }

  toggleBottomPart() {
    this.isBottomPartShowed = !this.isBottomPartShowed;
  }
}
