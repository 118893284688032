import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IOption} from "ng-select";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-sell-conditions',
  templateUrl: './sell-conditions.component.html',
  styleUrls: ['./sell-conditions.component.scss']
})
export class SellConditionsComponent implements OnInit {
  deliveryOptions: IOption[] = [
    {
      value: 'any',
      label: 'Any',
    },
    {
      value: 'ownCarrier',
      label: 'I want to use my own carrier',
    },
    {
      value: 'service1',
      label: 'Delivery service 1',
    },
    {
      value: 'service2',
      label: 'Delivery service 2',
    },
    {
      value: 'service3',
      label: 'Delivery service 3',
    },
  ];

  postageOptions: IOption[] = [
    {
      value: 'any',
      label: 'Any',
    },
    {
      value: 'pickupOnly',
      label: 'Pick up only',
    },
    {
      value: 'local',
      label: 'Local Shipping',
    },
    {
      value: 'international',
      label: 'International Shipping',
    },
    {
      value: 'free',
      label: 'Free Postage',
    },
  ];

  paymentOptions: IOption[] = [
    {
      value: 'any',
      label: 'Any',
    },
    {
      value: 'paypal',
      label: 'PayPal',
    },
    {
      value: 'moneyOrder',
      label: 'Money Order / Bank Cheque',
    },
    {
      value: 'personalCheque',
      label: 'Personal cheque',
    },
    {
      value: 'visa-mc',
      label: 'Visa / MasterCard',
    },
    {
      value: 'amex',
      label: 'American Express',
    },
    {
      value: 'Bank deposit',
      label: 'Bank deposit',
    },
    {
      value: 'cod',
      label: 'COD',
    },
    {
      value: 'payon',
      label: 'Payon pickup',
    },
    {
      value: 'escrow',
      label: 'Escrow',
    },
  ];

  form: FormGroup;
  @Output('navBlocksEmit') navBlocksEmitter = new EventEmitter();
  constructor() {
  }

  ngOnInit() {
    this.form = new FormGroup({
      postageDelivery: new FormControl(null),
      deliveryService: new FormControl('any', [Validators.required]),
      postageHandling: new FormControl('any'),
      termsConditions: new FormControl(null),
      termsConditionsFile: new FormControl(null),
      warrantyPolicy: new FormControl(null),
      warrantyPolicyFile: new FormControl(null),
      returnPolicy: new FormControl(null),
      returnPolicyFile: new FormControl(null),
      paymentMethod: new FormControl('any', [Validators.required])
    });
  }

  fileInputTrigger(el: HTMLInputElement) {
    el.click();
  }

  select() {
    console.log(this.form);
  }

  navigateBlocks(type) {
    this.navBlocksEmitter.emit(type);
  }
}
