import {Component} from "@angular/core";
import {ClassifiedComponent} from "../shared/components/classified/classified.component";

@Component({
  selector: 'app-sell',
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.scss']
})
export class SellComponent extends ClassifiedComponent {
  constructor() {
    super();
  }
}
