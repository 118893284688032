import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdditionalOption} from "../../sell/sell-additional/sell-additional.component";
import {CartService} from "../../shared/services/cart.service";

@Component({
  selector: 'app-buy-additional',
  templateUrl: './buy-additional.component.html',
  styleUrls: ['./buy-additional.component.scss']
})
export class BuyAdditionalComponent implements OnInit {
  @Input() isBlurred: boolean;

  @Output('navBlocksEmit') navBlocksEmitter = new EventEmitter();

  @Output('blurEmitter') blurEmitter = new EventEmitter();

  @Output('detailPicEmit') detailPicEmitter = new EventEmitter();

  @Output('openCartEmit') openCartEmitter = new EventEmitter();

  additionalOptions: AdditionalOption[] = [
    new AdditionalOption('Featured Listing', 'option1.jpg'),
    new AdditionalOption('High-Lighted', 'option2.jpg'),
    new AdditionalOption('Top Ad Result', 'option3.jpg'),
    new AdditionalOption('regular', 'option4.jpg'),
  ];

  cart: AdditionalOption[];

  @Output('openListingEmit') openListingEmitter = new EventEmitter();

  constructor(private cartService: CartService) { }

  ngOnInit() {
    this.cart = this.cartService.getProducts();
  }

  isCartEmpty() {
    return this.cart.length === 0;
  }

  addToCart(item: AdditionalOption) {
    if (this.isItemAdded(item)) {
      return;
    }

    this.cartService.addProduct(item);
  }

  isItemAdded(item: AdditionalOption) {
    return this.cart.indexOf(item) !== -1;
  }

  optionZoomIn(option: AdditionalOption) {
    this.blurEmitter.emit(!this.isBlurred);
    this.detailPicEmitter.emit(option.picture);
  }

  openCart() {
    this.openCartEmitter.emit(true);
  }

  openListing() {
    this.openListingEmitter.emit(true);
  }

  navigateBlocks(type) {
    this.navBlocksEmitter.emit(type);
  }
}
