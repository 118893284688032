import {Component, Input, OnInit} from '@angular/core';
import {Category} from "../../../sell/sell-item-info/sell-item-info.component";

@Component({
  selector: 'app-category-tiles',
  templateUrl: './category-tiles.component.html',
  styleUrls: ['./category-tiles.component.scss']
})
export class CategoryTilesComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }

}
