import {Component} from "@angular/core";
import {ClassifiedComponent} from "../shared/components/classified/classified.component";

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss']
})
export class BuyComponent extends ClassifiedComponent {
  constructor() {
    super();
  }
}
