import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {WizardContentComponent} from "../wizard-content/wizard-content.component";

@Component({
  selector: 'app-wizard-captions',
  templateUrl: './wizard-captions.component.html',
  styleUrls: ['./wizard-captions.component.scss']
})
export class WizardCaptionsComponent implements OnInit, AfterViewInit {
  @Input('blocks')
  blocks: WizardContentComponent[];
  @Output('changeBlock') changeBlockEmitter = new EventEmitter();
  @Output('captionsEmit') captionsEmitter = new EventEmitter();
  @ViewChild('captions', {static: false}) captions: ElementRef;
  constructor() { }

  ngOnInit() {

  }

  changeBlock($event: Event, block: WizardContentComponent) {
    $event.preventDefault();

    this.changeBlockEmitter.emit(block);
  }

  ngAfterViewInit(): void {
    this.captionsEmitter.emit(this.captions);
  }
}
