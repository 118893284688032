import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-file-name',
  templateUrl: './file-name.component.html',
  styleUrls: ['./file-name.component.scss']
})
export class FileNameComponent implements OnInit {
  @Input('inputFile') inputFile: HTMLInputElement;
  @Input('form') form: FormGroup;
  fileName: string;
  fileExt: string;
  constructor() { }

  ngOnInit() {
    [this.fileName, this.fileExt] = this.convertFileName(this.inputFile.value);
  }

  convertFileName(name) {
    const regExp = /(\w?\:?\\?[\w\-_\\]*\\+)([\w-_]+)(\.[\w-_]+)/gi;
    const [, , filename, fileExt] = regExp.exec(name);

    return [filename, fileExt];
  }

  removeItem() {
    this.form.patchValue({
      [this.inputFile.getAttribute('formcontrolname')]: null
    })
  }
}
