import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IOption} from "ng-select";
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-buy-item-info',
  templateUrl: './buy-item-info.component.html',
  styleUrls: ['./buy-item-info.component.scss']
})
export class BuyItemInfoComponent implements OnInit {
  form: FormGroup;
  options: IOption[] = [
    {
      label: 'All categories',
      value: 'all categories',
    },
    {
      label: 'Wheels and tires',
      value: 'wheels and tires',
    },
    {
      label: 'Suggested category',
      value: 'suggested category',
    }
  ];
  @Output('navBlocksEmit') navBlocksEmitter = new EventEmitter();
  constructor(private router: Router) { }

  ngOnInit() {
    this.form = new FormGroup({
      itemName: new FormControl(null, [Validators.required]),
      subtitle: new FormControl(null),
      category: new FormControl('all categories')
    })
  }

  navigateBlocks(type) {
    this.navBlocksEmitter.emit(type);
  }

  backToHome() {
    this.router.navigate(['']);
  }
}
