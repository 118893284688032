import {Component, Input, OnInit} from '@angular/core';
import {Category} from "../../../../sell/sell-item-info/sell-item-info.component";

@Component({
  selector: 'app-category-tile',
  templateUrl: './category-tile.component.html',
  styleUrls: ['./category-tile.component.scss']
})
export class CategoryTileComponent implements OnInit {
  @Input('category') category: Category;
  constructor() { }

  ngOnInit() {

  }

}
