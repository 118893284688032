import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WizardContentComponent} from "../wizard-content/wizard-content.component";

@Component({
  selector: 'app-wizard-controls',
  templateUrl: './wizard-controls.component.html',
  styleUrls: ['./wizard-controls.component.scss']
})
export class WizardControlsComponent implements OnInit {
  constructor() { }

  ngOnInit() {

  }
}
