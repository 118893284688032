import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-wizard-control',
  templateUrl: './wizard-control.component.html',
  styleUrls: ['./wizard-control.component.scss']
})
export class WizardControlComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
