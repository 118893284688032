import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-detailed-option',
  templateUrl: './detailed-option.component.html',
  styleUrls: ['./detailed-option.component.scss']
})
export class DetailedOptionComponent implements OnInit {
  @Input('detailPic')
  detailPic: string;
  @Output('closeDetailsEmit') closeDetailsEmitter = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  closeDetails() {
    this.closeDetailsEmitter.emit(false);
  }
}
