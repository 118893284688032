import {NgModule} from '@angular/core';
import {SelectModule} from "ng-select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Ng5SliderModule} from "ng5-slider";
import {ClassifiedComponent} from './components/classified/classified.component';


@NgModule({
  imports: [
    SelectModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule
  ],
  exports: [
    SelectModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    ClassifiedComponent
  ],
  declarations: [ClassifiedComponent]
})
export class SharedModule {
}
