import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CartService} from "../../shared/services/cart.service";

export class AdditionalOption {
  constructor(
    public name: string,
    public picture: string,
    public price: number = 4.99,
    public length: number = 7,
    public description: string = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud aboris nisi'
  ) {}
}

@Component({
  selector: 'app-sell-additional',
  templateUrl: './sell-additional.component.html',
  styleUrls: ['./sell-additional.component.scss']
})
export class SellAdditionalComponent implements OnInit {
  @Input()
  isBlurred: boolean;
  @Output('blurEmitter') blurEmitter = new EventEmitter();
  @Output('detailPicEmit') detailPicEmitter = new EventEmitter();
  @Output('openCartEmit') openCartEmitter = new EventEmitter();
  additionalOptions: AdditionalOption[] = [
    new AdditionalOption('Featured Listing', 'option1.jpg'),
    new AdditionalOption('High-Lighted', 'option2.jpg'),
    new AdditionalOption('Top Ad Result', 'option3.jpg'),
    new AdditionalOption('regular', 'option4.jpg'),
  ];

  cart: AdditionalOption[];
  @Output('navBlocksEmit') navBlocksEmitter = new EventEmitter();
  @Output('openListingEmit') openListingEmitter = new EventEmitter();

  constructor(private cartService: CartService) { }

  ngOnInit() {
    this.cart = this.cartService.getProducts();
  }

  isCartEmpty() {
    return this.cart.length === 0;
  }

  addToCart(item: AdditionalOption) {
    if (this.isItemAdded(item)) {
      return;
    }

    this.cartService.addProduct(item);
  }

  isItemAdded(item: AdditionalOption) {
    return this.cart.indexOf(item) !== -1;
  }

  optionZoomIn(option: AdditionalOption) {
    this.blurEmitter.emit(!this.isBlurred);
    this.detailPicEmitter.emit(option.picture);
  }

  openCart() {
    this.openCartEmitter.emit(true);
  }

  navigateBlocks(type) {
    this.navBlocksEmitter.emit(type);
  }

  openListing() {
    this.openListingEmitter.emit(true);
  }
}
