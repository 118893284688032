import {
  AfterContentInit, AfterViewChecked, AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild
} from '@angular/core';
import {WizardContentComponent} from "./wizard-content/wizard-content.component";

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit, AfterContentInit, AfterViewInit {
  @Input('badgeTitle')
  badgeTitle: string;
  @ContentChildren(WizardContentComponent)
  blocksQueryList: QueryList<WizardContentComponent>;
  blocks: WizardContentComponent[];
  activeIndex: number;
  @ViewChild('content', {static: false}) content: ElementRef;
  @ViewChild('wrapper', {static: false}) wrapper: ElementRef;
  captions: ElementRef;

  constructor() {
  }

  ngOnInit() {

  }

  ngAfterContentInit() {
    this.blocks = this.blocksQueryList.toArray();

    const activeBlocks: WizardContentComponent[] = this.blocks.filter(block => {
      return block.active;
    });

    if (activeBlocks.length === 0) {
      this.blocksQueryList.first.active = true;
    }

    this.activeIndex = this.getActiveIndex();
  }

  setActiveBlock(block: WizardContentComponent) {
    this.blocks.forEach(block => {
      block.active = false;
    });

    block.active = true;

    this.activeIndex = this.getActiveIndex();
  }

  getActiveIndex() {
    return this.blocks
      .indexOf(this.blocks
        .filter(block => {
          return block.active === true;
        })[0]);
  }

  getCaptions(captions) {
    this.captions = captions;
  }

  ngAfterViewInit(): void {
    // const wrapperHeight = this.wrapper.nativeElement.clientHeight;
    // const captionsHeight = this.captions.nativeElement.clientHeight;
    //
    // this.content.nativeElement.style.height = (wrapperHeight - captionsHeight) + 'px';
  }
}
