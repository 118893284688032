import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {IOption} from "ng-select";

@Component({
  selector: 'app-buy-delivery',
  templateUrl: './buy-delivery.component.html',
  styleUrls: ['./buy-delivery.component.scss']
})
export class BuyDeliveryComponent implements OnInit {
  @Output('navBlocksEmit') navBlocksEmitter = new EventEmitter();
  deliveryOptions: IOption[] = [
    {
      value: 'any',
      label: 'Any',
    },
    {
      value: 'ownCarrier',
      label: 'I want to use my own carrier',
    },
    {
      value: 'service1',
      label: 'Delivery service 1',
    },
    {
      value: 'service2',
      label: 'Delivery service 2',
    },
    {
      value: 'service3',
      label: 'Delivery service 3',
    },
  ];
  paymentOptions: IOption[] = [
    {
      value: 'any',
      label: 'Any',
    },
    {
      value: 'paypal',
      label: 'PayPal',
    },
    {
      value: 'moneyOrder',
      label: 'Money Order / Bank Cheque',
    },
    {
      value: 'personalCheque',
      label: 'Personal cheque',
    },
    {
      value: 'visa-mc',
      label: 'Visa / MasterCard',
    },
    {
      value: 'amex',
      label: 'American Express',
    },
    {
      value: 'Bank deposit',
      label: 'Bank deposit',
    },
    {
      value: 'cod',
      label: 'COD',
    },
    {
      value: 'payon',
      label: 'Payon pickup',
    },
    {
      value: 'escrow',
      label: 'Escrow',
    },
  ];
  form: FormGroup;
  constructor() { }

  ngOnInit() {
    this.form = new FormGroup({
      postageDelivery: new FormControl(null),
      deliveryService: new FormControl('any'),
      paymentMethod: new FormControl('any'),
    });
  }

  navigateBlocks(type) {
    this.navBlocksEmitter.emit(type);
  }
}
