import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LabelType, Options} from "ng5-slider";

import {Category} from "../../sell/sell-item-info/sell-item-info.component";

@Component({
  selector: 'app-buy-description',
  templateUrl: './buy-description.component.html',
  styleUrls: ['./buy-description.component.scss']
})
export class BuyDescriptionComponent implements OnInit {
  value: number = 100;
  options: Options = {
    floor: 0,
    ceil: 1000000,
    step: 500,
    translate: (value: number, label: LabelType): string => {
      const convertedValue = String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      switch (label) {
        case LabelType.Ceil:
          return '$' + convertedValue + '+';
        default:
          return '$' + convertedValue;
      }
    }
  };
  minValue = 10000;
  highValue = 100000;
  @Output('navBlocksEmit') navBlocksEmitter = new EventEmitter();
  form: FormGroup;
  categories: Category[] = [
    new Category('Wheels and Tires', 'wheels and tires', 'Subcategory', 'Subsubcategory', true)
  ];
  constructor() { }

  ngOnInit() {
    this.form = new FormGroup({
      priceRange: new FormControl(null),
      contactName: new FormControl(null, [Validators.required]),
      contactPhone: new FormControl(null),
      condition: new FormControl(null, [Validators.required]),
      location: new FormControl(null, [Validators.required]),
      itemDescription: new FormControl(null, [Validators.required]),
      usedOptions: new FormControl('excellent'),
    });
  }

  navigateBlocks(type) {
    this.navBlocksEmitter.emit(type);
  }
}
