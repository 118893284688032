import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IOption} from "ng-select";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

export class Category {
  constructor(
    public name: string,
    public value: string,
    public subCategory: string = 'Subcategory',
    public subSubCategory: string = 'Subsubcategory',
    public isActive: boolean = false
  ) {
  }
}

@Component({
  selector: 'app-sell-item-info',
  templateUrl: './sell-item-info.component.html',
  styleUrls: ['./sell-item-info.component.scss']
})
export class SellItemInfoComponent implements OnInit {
  itemInfoForm: FormGroup;
  options: IOption[] = [
    {
      label: 'All categories',
      value: 'all categories',
    },
    {
      label: 'Wheels and tires',
      value: 'wheels and tires',
    },
    {
      label: 'Suggested category',
      value: 'suggested category',
    }
  ];
  categories: Category[] = [];
  @Output('navBlocksEmit') navBlocksEmitter = new EventEmitter();

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.generateCategories();

    this.itemInfoForm = new FormGroup({
      'itemName': new FormControl(null, [Validators.required]),
      'category': new FormControl('all categories', [Validators.required])
    })
  }

  generateCategories() {
    this.categories = [];

    this.options.forEach(option => {
      if (option.label !== 'All categories') {
        this.categories.push(new Category(option.label, option.value));
      }
    })
  }

  indicateCategory(categoryEl: Category) {
    this.categories.forEach(category => {
      category.isActive = false;
    });

    categoryEl.isActive = true;
    this.itemInfoForm.patchValue({
      'category': categoryEl.value
    });

    this.categories = this.categories.filter(category => {
      return category.name === categoryEl.name;
    });
  }

  onSelected(e) {
    const label = e.label;

    this.generateCategories();

    if (label === 'All categories') {
      return;
    } else {
      this.categories = this.categories.filter(category => {
        return category.name === label;
      });
    }
  }

  isActiveCategoryExist() {
    return this.categories.filter(category => {
      return category.isActive === true;
    }).length > 0;
  }

  navigateBlocks(type) {
    this.navBlocksEmitter.emit(type);
  }

  backToHome() {
    this.router.navigate(['']);
  }
}
