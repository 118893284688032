import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CartService} from "../../services/cart.service";
import {AdditionalOption} from "../../../sell/sell-additional/sell-additional.component";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  @Output('closeCartEmit') closeCartEmitter = new EventEmitter();
  @Output('openListingEmit') openListingEmitter = new EventEmitter();
  products: AdditionalOption[] = [];
  constructor(public cartService: CartService) { }

  ngOnInit() {
    this.products = this.cartService.getProducts();
  }

  closeCart() {
    this.closeCartEmitter.emit(false);
  }

  removeProduct(product) {
    this.cartService.removeProduct(product);
  }

  listItem() {
    this.closeCart();
    this.openListingEmitter.emit(true);
  }
}
